import React from "react"
// import { Link } from "gatsby"

import Layout from "../components/layout/layout.js"
// import Image from "../components/image"
// import SEO from "../components/seo"
import BulletPoint from "../components/shared/bullet-point.js"
import SkillPoint from "../components/shared/skill-point"

const AboutPage = () => (
  <Layout>
    {/* <SEO title="Home" /> */}

    <div className="pb-0 pb-sm-2">
      <h1 className="title title--h1 title__separate">About Me</h1>
      <p>
        I'm UI/UX Designer from Dubai, UAE, working in Digital Media. I enjoy turning complex problems into simple, beautiful and
        intuitive designs.
      </p>
      {/*<p>*/}
      {/*  My job is to build your website so that it is functional and*/}
      {/*  user-friendly but at the same time attractive. Moreover, I add personal*/}
      {/*  touch to your product and make sure that is eye-catching and easy to*/}
      {/*  use. My aim is to bring across your message and identity in the most*/}
      {/*  creative way.*/}
      {/*</p>*/}
    </div>

    {/*// <!-- Experience -->*/}

    <div className="pb-0">
      <div className="row">
        <div className="col-12 col-lg-12">
          <h2 className="title title--h3">
            <img
              className="title-icon"
              src="../assets/icons/icon-experience.svg"
              alt=""
            />
            Experience
          </h2>
          <div className="timeline">
            {/*// <!-- Item -->*/}

            <BulletPoint
              title={"UX/UI & Front-end Developer"}
              subTitle={"Group Rhinos"}
              year={"(2018 — Present)"}
              description={
                "Working as UX/UI Designer. Hold stakeholder meetings and discuss sketched/inspiration ideas. Do user research and create User epics and user stories. Create User-Flows and Wireframes. Create Prototypes and have it tested with client and stakeholders. Create UI components in Flutter(Dart) or ReactJS."
              }
            />

            <BulletPoint
              title={"Web Developer cum Web Designer"}
              subTitle={"DURAR Advertising"}
              year={"(2016 — 2018)"}
              description={
                "Full stack web developer responsible for end-to-end web app development, deployment and\n" +
                "support for clients.\n" +
                "• Develop Static website using HTML, CSS, Bootstrap, Foundation, jQuery.\n" +
                "• Develop Dynamic website and web application using PHP, MySQL, JSON.\n" +
                "• Develop Content Management System using WordPress, WooCommerce.\n" +
                "• Develop customized projects using Laravel 5, MYSQL."
              }
            />

            <BulletPoint
              title={"Internship"}
              subTitle={"DURAR Advertising"}
              year={"(2015 — 2016)"}
              description={
                "Meeting clients and customizing the services according to their requirements.\n" +
                "• Designing website, landing pages, banners, social media posts. and mobile ads.\n" +
                "• Coding websites using HTML, CSS, JavaScript, Bootstrap3, jQuery, PHP."
              }
            />
          </div>
        </div>

        <div className="col-12 col-lg-6">
          <h2 className="title title--h3">
            <img
              className="title-icon"
              src="../assets/icons/icon-education.svg"
              alt=""
            />
            Education
          </h2>
          <div className="timeline">
            {/*// <!-- Item -->*/}

            <BulletPoint
              title={"Bachelors in IT"}
              subTitle={"SZABIST Dubai Campus"}
              year={"(2012 — 2016)"}
            />
          </div>
        </div>
      </div>
    </div>

    {/*// <!-- Skills -->*/}
    <div className="box-inner box-inner--rounded">
      <div className="row">
        <div className="col-12 col-lg-6 mt-4 mt-lg-0">
          <h2 className="title title--h3">UX Skills</h2>
          <div className="box box__second">
            {/*// <!-- Progress -->*/}
            <SkillPoint title={"User-centered Design"} />
            <SkillPoint title={"Sketching/Rendering"} />
            <SkillPoint title={"Storyboarding"} />
            <SkillPoint title={"Wireframing"} />
            <SkillPoint title={"Prototyping"} />
            <SkillPoint title={"Information Architecture"} />
            <SkillPoint title={"Adobe Creative Suite"} />
          </div>
        </div>

        <div className="col-12 col-lg-6">
          <h2 className="title title--h3">Design Skills</h2>
          <div className="box box__second">
            {/*// <!-- Progress -->*/}
            <SkillPoint title={"Web Design"} />
            <SkillPoint title={"Mobile App Design"} />
            <SkillPoint title={"Adobe XD"} />
            <SkillPoint title={"Adobe Photoshop"} />
            <SkillPoint title={"Adobe Illustrator"} />
            <SkillPoint title={"Adobe Premier Pro"} />
            <SkillPoint title={"Adobe After Effects"} />
          </div>
        </div>

        <div className="col-12 col-lg-6 mt-4 mt-lg-0">
          <h2 className="title title--h3">Coding Skills</h2>
          <div className="box box__second">
            {/*// <!-- Progress -->*/}
            <SkillPoint title={"HTML5, LESS, SASS, JavaScript"} />
            <SkillPoint title={"Flutter"} />
            <SkillPoint title={"ReactJS"} />
            {/*<SkillPoint title={"Angular 5"} />*/}
          </div>
        </div>
      </div>
    </div>

    {/* <p>Welcome to your new Gatsby site.</p>
    <p>Now go build something great.</p>
    <div style={{ maxWidth: `300px`, marginBottom: `1.45rem` }}>
      <Image />
    </div>
    <Link to="/page-2/">Go to page 2</Link> */}
  </Layout>
)

export default AboutPage
